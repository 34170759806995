






















































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import TranslatableComponent from "@/lib/mixins/translateable";
import magnoliaCmsApi, {
  RepresentativeContactDetails,
} from "@/api/magnoliaCmsApi";
import { AxiosResponse } from "axios";
import ContactCard from "@/components/contactCards/ContactCard.vue";
import Loader from "@/components/Loader.vue";
import { AccountPermission } from "@/store/modules/user";
import { namespace } from "vuex-class";
import { personalisationApi } from "@/api/personalisation";
import TheHeader from "@/components/TheHeader.vue";
import Page from "@/components/layouts/Page.vue";

const user = namespace("user");

@Component({
  components: { ContactCard, Loader, Page, TheHeader },
})
export default class Contact extends mixins(TranslatableComponent) {
  @user.State("accountPermissions")
  private accounts!: Array<AccountPermission> | null;

  private isLoading: boolean = true;
  private representativeContactDetails: RepresentativeContactDetails[] = [];

  mounted() {
    this.init();
  }

  async init() {
    const listOfEmails = await this.getRepresentatives(this.accounts || []);

    this.representativeContactDetails =
      await this.getRepresentativeContactDetails(listOfEmails);

    this.isLoading = false;
  }

  getRepresentatives(accounts: AccountPermission[]) {
    const representativesPromises: Promise<string[]>[] = [];

    accounts.forEach((account) => {
      representativesPromises.push(
        personalisationApi
          .representatives(account.accountId)
          .then((response: AxiosResponse<string[]>) => {
            return response.data;
          })
      );
    });

    return Promise.allSettled(representativesPromises).then((result) => {
      const listOfEmails = result
        .filter(
          (result): result is PromiseFulfilledResult<string[]> =>
            result.status === "fulfilled" && !!result.value
        )
        .map((result) => {
          return result.value;
        })
        .flat();

      return Array.from(new Set(listOfEmails));
    });
  }

  getRepresentativeContactDetails(listOfEmails: string[]) {
    const RepresentativeContactDetailsPromises: Promise<RepresentativeContactDetails>[] =
      [];

    listOfEmails.forEach((email: string) => {
      RepresentativeContactDetailsPromises.push(
        magnoliaCmsApi
          .getRepresentativeContactDetails(email)
          .then((response: AxiosResponse<RepresentativeContactDetails>) => {
            return response.data;
          })
      );
    });

    return Promise.allSettled<Promise<RepresentativeContactDetails>[]>(
      RepresentativeContactDetailsPromises
    ).then((result) => {
      return result
        .filter(
          (
            result
          ): result is PromiseFulfilledResult<RepresentativeContactDetails> =>
            result.status === "fulfilled" && !!result.value
        )
        .map((result) => {
          return result.value;
        })
        .sort((a, b) =>
          a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase())
        );
    });
  }
}
